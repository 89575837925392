import {
  FC,
  PropsWithChildren,
  createContext,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react';
import {
  MessagePayload,
  Messaging,
  deleteToken,
  getMessaging,
  getToken,
  isSupported,
  onMessage
} from 'firebase/messaging';
import { useLocation } from 'react-router';
import { toast } from 'react-toastify';
import { swRegistration } from 'src';
import { getStreamApi } from 'src/api/getstream-api';
import { ChatToast } from 'src/components/toasts/chat-toast';
import { DetailsToast } from 'src/components/toasts/details-toast';
import { CloudTokenState, useCloudToken } from 'src/hooks/use-cloud-token';
import { firebaseGetApp } from 'src/libs/firebase';
import { useDispatch } from 'src/store';

const registerNotifications = async (current?: NotificationPermission) => {
  if (current === undefined) current = Notification.permission;
  // NOTE: iOS always returns "denied", unless user has already "granted" _before_
  if (current === 'default') current = await Notification.requestPermission();
  return current;
};

const notify = async (title: string, body: string) => {
  await swRegistration?.showNotification(title, {
    body: body
  });
};

type State = CloudTokenState & {
  type: 'web';
  permitted: boolean;
  handleRequest?: () => Promise<any>;
  handleLogout?: () => Promise<any>;
  notify: (title: string, body: string) => Promise<any>;
};

const initialState: State = {
  type: 'web',
  initialized: false,
  requested: false,
  permitted: false,
  failed: false,
  notify
};

export interface NotificationsContextType extends State {}

export const NotificationsContext = createContext<NotificationsContextType>({
  ...initialState
});

interface NotificationsProviderProps {
  vapidKey?: string;
}

export const NotificationsProvider: FC<
  PropsWithChildren<NotificationsProviderProps>
> = (props) => {
  const { vapidKey, children } = props;
  const dispatch = useDispatch();

  // store the current permission state
  const [permission, setPermission] = useState<NotificationPermission>();
  // reference to the firebase messaging instance
  const messaging = useRef<Messaging | undefined>();

  const handleNotification = useCallback((notification: MessagePayload) => {
    if (notification.data?.sender === 'stream.chat') {
      // unread message have probably changed
      dispatch(getStreamApi.util.invalidateTags(['unread']));
      return toast(<ChatToast message={notification.data?.message_id} />);
    }

    return toast(
      <DetailsToast
        title={notification.notification?.title}
        secondary={notification.notification?.body}
      />,
      { position: 'bottom-center' }
    );
  }, []);

  const handleInitialize = useCallback(async () => {
    if (await isSupported()) messaging.current = getMessaging(firebaseGetApp());
    else console.log('Notifications not supported');
  }, []);

  const handleRegister = useCallback(async () => {
    // notifications must be registered immediately to work on iOS
    // (as this method is called directly from button click)
    if (!messaging.current) return;

    const permission = await registerNotifications();
    setPermission(permission);

    if (permission === 'granted') {
      // reset badge after application start
      // TODO: track unread
      if ('clearAppBadge' in navigator) navigator.clearAppBadge();
      // receive a device specific token
      return await getToken(messaging.current, { vapidKey });
    }
  }, [vapidKey]);

  const handleUnregister = useCallback(async () => {
    if (messaging.current) await deleteToken(messaging.current);
  }, []);

  const { state, handleRequest, handleLogout } = useCloudToken({
    onInitialize: handleInitialize,
    onRegister: handleRegister,
    onUnregister: handleUnregister
  });

  // register for (foreground) messages
  // (when the token becomes available)
  useEffect(() => {
    if (messaging.current && state.token !== undefined)
      return onMessage(messaging.current, handleNotification);
  }, [handleNotification, state.token]);

  // HACK: iOS specific workaround (see registerNotifications())
  // this checks for notification permission changes on path change
  const { pathname } = useLocation();
  useEffect(
    () => {
      if (permission === 'denied' && Notification.permission === 'granted')
        // re-run the request permission routine to retain a token
        handleRequest();
    },
    // explicitly check on path change
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pathname]
  );

  return (
    <NotificationsContext.Provider
      value={{
        type: 'web',
        ...state,
        permitted: permission === 'granted',
        handleRequest,
        handleLogout,
        notify
      }}
    >
      {children}
    </NotificationsContext.Provider>
  );
};
