import { FC } from 'react';
import { Box } from '@mui/material';

interface DotProps {
  color: string;
}

export const Dot: FC<DotProps> = (props) => {
  const { color } = props;

  return (
    <Box
      sx={{
        width: 10,
        height: 10,
        borderRadius: 5,
        backgroundColor: color
      }}
    />
  );
};
