import type { FC, ReactNode } from 'react';
import { createContext, useCallback, useEffect, useReducer } from 'react';
import { sendPasswordResetEmail, updatePassword } from '@firebase/auth';
import * as Sentry from '@sentry/browser';
import {
  EmailAuthProvider,
  ParsedToken,
  confirmPasswordReset,
  signInWithEmailAndPassword as firebaseInWithEmailAndPassword,
  signInWithEmailLink as firebaseInWithEmailLink,
  signOut as firebaseOut,
  onAuthStateChanged,
  reauthenticateWithCredential
} from 'firebase/auth';
import PropTypes from 'prop-types';
import { firebaseGetAuth, firebaseInit } from 'src/libs/firebase';
import { Login as EnhancedUser } from 'src/types/user';
import { Issuer } from 'src/utils/auth';
import {
  TenantData,
  TenantKeys,
  TenantStyle,
  tenantApi
} from '../../api/tenant-api';

interface State {
  isInitialized: boolean;
  isAuthenticated: boolean;
  user: EnhancedUser | null;
  // tenant specific data
  tenant: TenantData | null;
  keys: TenantKeys | null;
  style: TenantStyle | null;
}

enum ActionType {
  AUTH_STATE_CHANGED = 'AUTH_STATE_CHANGED',
  TENANT_CHANGED = 'TENANT_CHANGED'
}

type AuthStateChangedAction = {
  type: ActionType.AUTH_STATE_CHANGED;
  payload: {
    isAuthenticated: boolean;
    user: EnhancedUser | null;
    tenant: TenantData | null;
    keys: TenantKeys | null;
    style: TenantStyle | null;
  };
};

type TenantChangedAction = {
  type: ActionType.TENANT_CHANGED;
  payload: {
    tenant: TenantData | null;
  };
};

type Action = AuthStateChangedAction | TenantChangedAction;

const initialState: State = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  tenant: null,
  keys: null,
  style: null
};

const reducer = (state: State, action: Action): State => {
  if (action.type === 'AUTH_STATE_CHANGED') {
    const { isAuthenticated, user, tenant, keys, style } = action.payload;
    // track initial authentication update
    if (!state.isInitialized) console.log('Authentication initialized', !!user);

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
      tenant,
      keys,
      style
    };
  }

  if (action.type === 'TENANT_CHANGED') {
    const { tenant } = action.payload;

    return {
      ...state,
      tenant
    };
  }

  return state;
};

export interface AuthContextType extends State {
  issuer: Issuer.Firebase;
  token?: ParsedToken;
  signInWithEmailLink: (email: string, url: string) => Promise<any>;
  signInWithEmailAndPassword: (email: string, password: string) => Promise<any>;
  signOut: () => Promise<void>;
  updatePassword: (current: string, password: string) => Promise<any>;
  resetPassword: (email: string) => Promise<any>;
  resetPasswordWithEmailLink: (
    oobCode: string,
    password: string
  ) => Promise<any>;
  setTenant: (tenant: TenantData | null) => Promise<any>;
}

export const AuthContext = createContext<AuthContextType>({
  ...initialState,
  issuer: Issuer.Firebase,
  signInWithEmailLink: () => Promise.resolve(),
  signInWithEmailAndPassword: () => Promise.resolve(),
  signOut: () => Promise.resolve(),
  updatePassword: (current: string, password: string) => Promise.resolve(),
  resetPassword: (email: string) => Promise.resolve(),
  resetPasswordWithEmailLink: (oobCode: string, password: string) =>
    Promise.resolve(),
  setTenant: (tenant) => Promise.resolve()
});

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthProvider: FC<AuthProviderProps> = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);

  // request tenant data which is then provided to firebase
  const [getTenant] = tenantApi.useLazyGetTenantQuery();
  const [getKeys] = tenantApi.useLazyGetKeysQuery();
  const [getStyle] = tenantApi.useLazyGetStyleQuery();

  // TODO: this does probably not work
  // this is used when the tenant.json can not be loaded from backend
  // (because user is not logged in and tenant is not known)
  // TODO: place code here instead of partially in login screen
  const setTenant = useCallback(async (tenant: TenantData | null) => {
    const auth = firebaseGetAuth();
    // update tenant in firebase
    auth.tenantId = tenant?.tenantId || null;
    // update tenant in state
    dispatch({
      type: ActionType.TENANT_CHANGED,
      payload: {
        tenant
      }
    });
  }, []);

  useEffect(() => {
    const requestTenantData = async () => {
      try {
        console.log('Requesting tenant data');
        return {
          tenant: await getTenant().unwrap(),
          keys: await getKeys().unwrap(),
          style: await getStyle().unwrap()
        };
      } catch (error) {
        console.log(error);
        // proceed without tenant data
        return { tenant: null, keys: null, style: null };
      }
    };

    const initialize = async (
      tenant: TenantData | null,
      keys: TenantKeys | null,
      style: TenantStyle | null
    ) => {
      if (!keys) return console.warn('Missing firebase keys');

      firebaseInit(keys, tenant?.tenantId);
      const auth = firebaseGetAuth();
      // register firebase authentication handler
      onAuthStateChanged(auth, async (firebaseUser) => {
        console.log('Authentication changed', !!firebaseUser);

        // extract the complete user profile
        // to make it available in the entire app
        const tokenResult = await firebaseUser?.getIdTokenResult();
        const user = firebaseUser &&
          tokenResult && {
            uid: firebaseUser.uid,
            avatar: firebaseUser.photoURL || undefined,
            email: firebaseUser.email || undefined,
            tokenResult: tokenResult,
            groups: tokenResult.claims.groups as string[]
          };

        // TODO: check for duplicates
        Sentry.setTag('tenant', tenant?.tenantId || 'unknown');

        dispatch({
          type: ActionType.AUTH_STATE_CHANGED,
          payload: {
            isAuthenticated: !!user,
            user: user || null,
            tenant,
            keys,
            style
          }
        });
      });
    };

    requestTenantData()
      .then(({ tenant, keys, style }) => initialize(tenant, keys, style))
      .then(() => console.log('Tenant ready'))
      .catch((error) => {
        console.error('Failed to load tenant');
        console.log(error);
      });

    // this effect is executed exactly once to initialize firebase
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const signInWithEmailAndPassword = useCallback(
    async (email: string, password: string): Promise<void> => {
      await firebaseInWithEmailAndPassword(firebaseGetAuth(), email, password);
    },
    []
  );

  const signInWithEmailLink = useCallback(
    async (email: string, url: string): Promise<void> => {
      await firebaseInWithEmailLink(firebaseGetAuth(), email, url);
    },
    []
  );

  const signOut = useCallback(async (): Promise<void> => {
    await firebaseOut(firebaseGetAuth());
  }, []);

  const _resetPassword = useCallback(async (email: string): Promise<void> => {
    await sendPasswordResetEmail(firebaseGetAuth(), email);
  }, []);

  const _updatePassword = useCallback(
    async (current: string, password: string): Promise<void> => {
      const user = firebaseGetAuth().currentUser;
      if (!user?.email) return;

      // changing password requires recent re-authentication
      const credential = EmailAuthProvider.credential(user.email, current);
      await reauthenticateWithCredential(user, credential);
      await updatePassword(user, password);
    },
    []
  );

  const resetPasswordWithEmailLink = useCallback(
    async (oobCode: string, password: string) => {
      await confirmPasswordReset(firebaseGetAuth(), oobCode, password);
    },
    []
  );

  return (
    <AuthContext.Provider
      value={{
        ...state,
        issuer: Issuer.Firebase,
        signInWithEmailAndPassword,
        signInWithEmailLink,
        signOut,
        updatePassword: _updatePassword,
        resetPassword: _resetPassword,
        resetPasswordWithEmailLink,
        setTenant
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export const AuthConsumer = AuthContext.Consumer;
