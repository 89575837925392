import type { FC, PropsWithChildren } from 'react';
import { createContext, useEffect, useMemo, useState } from 'react';
import { Theme, useMediaQuery } from '@mui/material';
import * as Sentry from '@sentry/browser';
import { enhancedApi } from 'src/api/enhanced-api';
import { GetStreamAuth } from 'src/api/getstream-api';
import { Loader } from 'src/components/loader';
import { useMe } from 'src/hooks/use-me';
import { getFullName } from 'src/utils/get-full-name';

export interface ChatApiContextType {
  auth: GetStreamAuth;
  // layout
  mobile: boolean;
  desktop: boolean;
  // offline behaviour
  online: boolean;
  connect: (channelId?: string) => void;
  defaultChannelId?: string;
}

export const ChatApiContext = createContext<ChatApiContextType | undefined>(
  undefined
);

/**
 * Provides shared data for the chat API
 *
 * This is used in addition to the GetStream context
 * to provide custom data for the components,
 * which can not be passed down easily in every place.
 *
 * TODO: maybe move to authGuard and apply to <Layout />
 */
export const ChatApiProvider: FC<PropsWithChildren> = (props) => {
  const { children } = props;

  const { data: token } = enhancedApi.useGetApiChatTokenQuery(undefined, {
    // request new token after 4 minutes (expires in 5 minutes)
    // TODO: re-fetch token when it is rejected
    pollingInterval: 4 * 60 * 1000
  });

  // valid profile is required
  const profile = useMe();
  // connection state (contains default channel id)
  const [online, setOnline] = useState<string>();

  // break points for chat layout
  const mobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const desktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('xl'));

  const context = useMemo(() => {
    if (token === undefined || profile?.uid === undefined) return undefined;

    // chat context must be cached properly
    console.log('Loading chat context');
    return {
      auth: { token, uid: profile.uid },
      mobile,
      desktop,
      online: !!online,
      connect: setOnline,
      defaultChannelId: online
    };
  }, [token, profile?.uid, mobile, desktop, online]);

  useEffect(() => {
    if (profile?.uid === undefined) return;

    console.log('Setting sentry user');
    Sentry.setUser({
      email: profile.email || undefined,
      id: profile.uid || undefined,
      username: getFullName(profile)
    });
  }, [profile]);

  // block until context is available
  if (!context) return <Loader />;

  return (
    <ChatApiContext.Provider value={context}>
      {children}
    </ChatApiContext.Provider>
  );
};

export const ChatApiConsumer = ChatApiContext.Consumer;
