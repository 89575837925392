import { IdTokenResult } from 'firebase/auth';
import {
  NotificationSettings,
  UserTagNotificationSetting
} from 'src/api/deinverband-api';
import { CategoryOrId, Company } from './company';
import { Contact } from './contact';
import { Attendance } from './occasion';
import { PostNotification } from './post';

export const phone = /^\+?[\d\s()]*$/;
export const genders = ['female', 'male', 'diverse'] as const;

export type Gender = (typeof genders)[number];
export type Genders = { [K in Gender]: string };

export type GroupId = number;
export type GroupOrId = { id: GroupId; name?: string };
export type Group = { id: GroupId; name: string };
export type GroupOrAll = GroupOrId | { id: null };

export type RoleId = number;
export type RoleOrId = { id: RoleId; name?: string };
export type Role = { id: RoleId; name: string };
export type RoleOrAll = GroupOrId | { id: null };

// explicit group roles without all (i.e. user data)
export type GroupRole = { group: GroupOrId; role: RoleOrId };
// group roles with all (i.e. for filters)
export type GroupRoleWithAll = { group: GroupOrAll; role: RoleOrAll };

export const genderData: Genders = {
  male: 'Herr',
  female: 'Frau',
  diverse: 'Divers'
};

/// Basic information on any user
export interface Member {
  id: number;
  avatar?: string;
  email?: string;
  gender?: Gender;
  firstName?: string;
  lastName?: string;

  // employment
  companyName?: string;
  companyLocation?: string;

  // advanced data (only for some requests)
  groupRoles?: GroupRole[];
  categoryAccess?: CategoryOrId[];
  external: boolean;
  companyAccount?: boolean;
}

/// Possibly unpopulated member
export type MemberOrId = { id?: number } | Member;

/// User with attendance info
export interface Attendee {
  member: Member;
  attendance: Attendance;
}

/// User with notification info
export interface Recipient {
  member: Member;
  email?: PostNotification;
  push?: PostNotification;
}

/// Extended information on a user as provided by JWT
export interface Login {
  uid: string;
  tokenResult?: IdTokenResult;
  // token encoded data
  groups?: string[];
  email?: string;
  avatar?: string;
}

/// Extended information on a user profile as provided by the backend
export interface Profile {
  id: number;
  uid?: string;
  avatar?: string;
  email?: string;
  gender: Gender;
  firstName?: string;
  lastName?: string;
  contact?: Contact;

  // employment
  company?: Company;

  // attributes
  groupRoles: GroupRole[];
  categoryAccess: CategoryOrId[];
  companyAdmin: boolean;
  tenantAdmin: boolean;

  // advanced data
  external: boolean;
  companyAccount: boolean;
  defaultNotifications: NotificationSettings;
  tagNotifications: UserTagNotificationSetting[];
}
