import { useMemo } from 'react';
import { useGetApiUsersMeQuery } from 'src/api/deinverband-api';
import { mapUserDetailsToProfile } from 'src/mappings/user-details-to-profile';
import { Profile } from 'src/types/user';
import { useAuth } from './use-auth';

// TODO: move whole component to auth guard and provide context
export const useMe = (): Profile | undefined => {
  const { user } = useAuth();
  const { data } = useGetApiUsersMeQuery(undefined, { skip: !user });

  return useMemo((): Profile | undefined => {
    // populate profile with auth data
    const profile = mapUserDetailsToProfile(data);

    return (
      profile && {
        ...profile,
        avatar: user?.avatar,
        uid: user?.uid
      }
    );
  }, [data, user]);
};
