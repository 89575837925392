import type { FC } from 'react';
import { Capacitor } from '@capacitor/core';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Helmet } from 'react-helmet-async';
import { Provider as ReduxProvider } from 'react-redux';
import { useRoutes } from 'react-router-dom';
import { DemoBanner } from './components/demo-banner';
import { RTL } from './components/rtl';
import { SplashScreen } from './components/splash-screen';
import { gtmConfig } from './config';
import { AuthConsumer, AuthProvider } from './contexts/auth/firebase-context';
import { NotificationsProvider as CapacitorNotificationsProvider } from './contexts/notifications/capacitor-context';
import { NotificationsProvider as FirebaseNotificationsProvider } from './contexts/notifications/firebase-context';
import {
  SettingsConsumer,
  SettingsProvider
} from './contexts/settings-context';
import { useAnalytics } from './hooks/use-analytics';
import { useNprogress } from './hooks/use-nprogress';
import { routes } from './routes';
import { store } from './store';
import { createTheme } from './theme';

import 'react-quill/dist/quill.snow.css';
import 'simplebar-react/dist/simplebar.min.css';
import 'react-toastify/dist/ReactToastify.css';
import SentryUserInfo from './components/sentry-user-info';

// select the correct providers
const NotificationsProvider =
  Capacitor.getPlatform() === 'web'
    ? FirebaseNotificationsProvider
    : CapacitorNotificationsProvider;

export const App: FC = () => {
  useAnalytics(gtmConfig);
  useNprogress();

  const element = useRoutes(routes);

  return (
    <ReduxProvider store={store}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <AuthProvider>
          <AuthConsumer>
            {(auth) => (
              <>
                <SentryUserInfo
                  email={auth.user?.email}
                  userId={auth.user?.uid}
                  tenantId={auth.tenant?.tenantId}
                />
                <SettingsProvider>
                  <SettingsConsumer>
                    {(settings) => {
                      const theme = createTheme({
                        tenantPalette: auth.style?.palette,
                        contrast: settings.contrast,
                        direction: settings.direction,
                        paletteMode: settings.paletteMode,
                        responsiveFontSizes: settings.responsiveFontSizes
                      });

                      // Prevent guards from redirecting
                      const showSlashScreen = !auth.isInitialized;

                      return (
                        <ThemeProvider theme={theme}>
                          <Helmet>
                            <meta
                              name="color-scheme"
                              content={settings.paletteMode}
                            />
                            <meta
                              name="theme-color"
                              content={theme.palette.neutral[900]}
                            />
                          </Helmet>
                          <RTL direction={settings.direction}>
                            <CssBaseline />
                            {showSlashScreen ? (
                              <SplashScreen />
                            ) : (
                              <NotificationsProvider
                                vapidKey={auth.keys?.vapidKey}
                              >
                                <DemoBanner />
                                {element}
                              </NotificationsProvider>
                            )}
                            {/* TODO: restore when login works properly */}
                            {/*<Toaster />*/}
                          </RTL>
                        </ThemeProvider>
                      );
                    }}
                  </SettingsConsumer>
                </SettingsProvider>
              </>
            )}
          </AuthConsumer>
        </AuthProvider>
      </LocalizationProvider>
    </ReduxProvider>
  );
};
