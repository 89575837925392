import type { FC } from 'react';
import { Toaster } from 'src/components/toaster';
import { ChatApiProvider } from 'src/contexts/chat/chat-api-context';
import { AuthGuard } from 'src/guards/auth-guard';

export const withAuthGuard =
  <P extends object>(Component: FC<P>): FC<P> =>
  (props: P) =>
    (
      <AuthGuard>
        <ChatApiProvider>
          <Component {...props} />
          <Toaster />
        </ChatApiProvider>
      </AuthGuard>
    );
