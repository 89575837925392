import React, { useEffect } from 'react';
import * as Sentry from '@sentry/browser';

type SentryUserInfoProps = {
  email: string | undefined;
  userId: string | undefined;
  tenantId: string | undefined;
};

// TODO: this looks like duplicate code, check the other Sentry.setUser
const SentryUserInfo: React.FC<SentryUserInfoProps> = (props) => {
  const { email, userId, tenantId } = props;
  useEffect(() => {
    if (!tenantId) return;
    Sentry.setTag('tenant', tenantId);
    if (!email || !userId) return;
    Sentry.setUser({
      id: userId,
      email
    });
  }, [email, userId, tenantId]);
  return null;
};

export default SentryUserInfo;
