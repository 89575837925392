import { Suspense } from 'react';
import { setDefaultOptions } from 'date-fns';
import { de } from 'date-fns/locale';
import ReactDOM from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import { setLicenseKey } from 'survey-core';
import { registerSW } from 'virtual:pwa-register';
import { App } from './app';
import AppUrlListener from './components/app-url-listener';
import { DetailsToast } from './components/toasts/details-toast';
import './libs/sentry';

export let swRegistration: ServiceWorkerRegistration | undefined = undefined;

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

// date-fns
setDefaultOptions({ locale: de });
// survey-creator
setLicenseKey(
  'ZDQzOTgxOGMtNGQzMC00YjhhLWFlZmMtYzhiNWM2MzYzMWEyOzE9MjAyNC0xMi0zMA=='
);

root.render(
  <HelmetProvider>
    <BrowserRouter>
      <AppUrlListener />
      <Suspense>
        <App />
      </Suspense>
    </BrowserRouter>
  </HelmetProvider>
);

// auto-refresh every hour
const refreshInterval = 1000 * 60 * 60;

// register the service worker to enable PWA
const updateSW = registerSW({
  onRegisteredSW(script, registration) {
    // store registration for external use
    swRegistration = registration;

    if (registration)
      setInterval(() => {
        registration.update();
      }, refreshInterval);
  },
  onNeedRefresh() {
    console.log('Update available');
    requestRefresh();
  },
  onOfflineReady() {
    console.log('Offline ready');
  }
});

function requestRefresh() {
  toast(
    <DetailsToast
      title="Neue Version verfügbar"
      secondary="Die Seite muss neu geladen werden"
      icon="fa6-solid:rotate-right"
      onLink={() => {
        console.log('Refreshing page');
        // call the refresh handler
        updateSW();
      }}
    />,
    { autoClose: false, closeOnClick: false, closeButton: false }
  );
}
